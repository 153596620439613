body {
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  overflow-wrap: anywhere;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: hsla(287, 23%, 65%, 1);
  background: linear-gradient(to bottom, #c9b7bf, #9d38c0);
  font-size: 1.23rem;
}

h1 {
  margin: 0;
}