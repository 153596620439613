.App {
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 48px);
}

.site-title {
  margin-bottom: 126px;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
}

.rate-input .input-container {
  display: flex;
  align-items: center;
}

.rate-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 36px;
}

.rate-input .input-container button {
  border: 0;
  outline: 0;
  min-width: 42px;
  height: 42px;
  border-radius: 24px;
  background-color: #5b5b5b;
  color: white;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rate-input .input-container input {
  margin: 0 20px;
  border: none;
  padding: 24px;
  border-radius: 42px;
  cursor: pointer;
  outline: 0;
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 200px;
}

.rate-input .input-container label {
  margin-bottom: 24px;
  font-size: 24px;
}

.result {
  margin-top: 48px;
  font-size: 4.20em;
  font-weight: bold;
  overflow-wrap: anywhere;
}

.how-to {
  margin-top: 360px;
}

input[type=number]::-webkit-inner-spin-button {
  display: none;
}

footer {
  max-width: 800px;
  text-align: center;
  margin: auto;
  padding: 0 12px 42px;
  margin-top: 128px;
}

.formula {
  color: white;
  display: block;
  font-weight: bold;
  margin: 8px 0 6px;
}

.nav-links {
  margin-top: auto;
  padding: 0 24px;
}

.nav-links ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nav-links ul li {
  margin-bottom: 18px;
}

.nav-links ul a {
  color: white;
}

@media only screen and (max-width: 1024px) {
  .site-title {
    margin-bottom: 64px;
  }

  .main-section {
    padding: 0 12px;
  }

  .nav-links ul {
    flex-direction: column;
  }
  .nav-links ul a {
    margin-bottom: 24px;
  }
}

.tLeftAligned {
  text-align: left;;
}

.detailed-results ul {
  list-style: none;
  text-align: left;
  padding-left: 0;
  text-align: center;
}